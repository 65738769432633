export default [
  {
    header: "Pages",
    icon: "FileIcon",
    resource: "User",
    action: "read",
    children: [
      {
        title: "Home",
        route: "home",
        icon: "HomeIcon",
      },
      {
        title: "Second Page",
        route: "second-page",
        icon: "FileIcon",
      },
    ],
  },
  {
    header: "Pages",
    icon: "FileIcon",
    resource: "User",
    action: "read",
    children: [
      {
        title: "Home",
        route: "home",
        icon: "HomeIcon",
      },
      {
        title: "Second Page",
        route: "second-page",
        icon: "FileIcon",
      },
    ],
  },
];
